import {environment} from '../environments/environment';

export const appConstants = {
  baseUrl: environment.apiUrl,
  api: {
    create_area: 'area/create',
    create_city: 'city/create',
    create_donor: 'donor/create',
    crate_blood_drive: 'blood-drive/create',
    create_agency: 'agency/create',
    create_bag: 'bag/create',
    create_location: 'location/create',
    create_staff: 'staff/create',
    disable_donor: 'donor/disable',
    disable_blood_drive: 'blood-drive/disable',
    disable_staff: 'staff/disable',
    disable_sponsor: 'sponsor/disable',
    delete_donor: 'donor/archived',
    delete_agency: 'agency/archived',
    delete_blood_drive: 'blood-drive/archived',
    delete_blood_drive_location: 'location/archived',
    delete_staff: 'staff/archived',
    delete_sponsor: 'sponsor/archived',
    forget_password: 'site/forgot-password',
    get_all_donor: 'donor/all',
    get_all_state: 'state/all',
    get_all_blood_drive_location: 'location/all',
    get_all_questions: 'question-category/all-with-title',
    get_all_blood_drive: 'blood-drive/all',
    get_all_staff: 'staff/all',
    get_staff_index: 'staff/index',
    get_all_bag: 'bag/all',
    get_all_sponser: 'sponsor/all',
    get_all_agency: 'agency/all',
    generate_blood_drive: 'blood-drive/generate',
    generate_donor_list: 'donor/generate',
    generate_bag_list: 'bag/generate',
    generate_all_blood_drive_location: 'location/generate',
    generate_all_staff: 'staff/generate',
    last_questionnaire: 'questionnaire/last',
    login: 'site/login'

  }
};
