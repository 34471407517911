import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {appConstants} from "../../app.constant";
import {MatSnackBar} from "@angular/material";
import {AuthenticationService} from "../authentication.service";

@Component({
	selector: 'app-forget-password',
	templateUrl: './forget-password.component.html',
	styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
	forgetPasswordForm: FormGroup;
	email: FormControl;

	constructor(private _snackBar: MatSnackBar, private authService: AuthenticationService) {

	}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.email = new FormControl(null, [Validators.required, Validators.email]);

		this.forgetPasswordForm = new FormGroup({
			email: this.email
		});
	}

	onSubmit() {
		if (this.forgetPasswordForm.valid) {
			const postObj = this.forgetPasswordForm.getRawValue();
			this.authService.post(appConstants.api.forget_password, postObj).subscribe((res: any) => {
				if (res.error) {
					this._snackBar.open(res.error, '', {
						duration: 2000
					});
				}
			}, (err) => {
				this._snackBar.open('Something went wrong', '', {
					duration: 2000
				});
			});

		} else {
			this._snackBar.open('Required Parameter Missing', '', {
				duration: 2000
			});
		}
	}

}
