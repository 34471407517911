import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {AuthenticationService} from "./authentication.service";
import {RouterModule} from "@angular/router";

@NgModule({
	imports: [
		SharedModule,
		ReactiveFormsModule,
		RouterModule,

	],
	declarations: [LoginComponent, ForgetPasswordComponent],
	providers: [AuthenticationService]
})
export class AuthenticationModule {
}
