import {Injectable} from '@angular/core';
import {HttpbaseService} from '../shared/httpbase.service';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends HttpbaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  setUser(user) {
    if (user['auth_key']) {
      this.setKey(user['auth_key']);
    }
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    let user;
    if (localStorage.getItem('user')) {
      user = localStorage.getItem('user');
    }
    return JSON.parse(user || '{}');
  }

  setKey(key: string) {
    sessionStorage.setItem('key', key);
  }

  getKey() {
    return sessionStorage.getItem('key');
  }
}
