import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {appConstants} from '../app.constant';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export abstract class HttpbaseService {

  constructor(public httpClient: HttpClient) {

  }

  get(methodUrl, params?: any) {
    const completeApiUrl: string = this.getCompleteApiUrl(methodUrl);
    if (params) {
      return this.httpClient.get(completeApiUrl, {params: params});
    } else {
      return this.httpClient.get(completeApiUrl);
    }
  }

  getFile(methodUrl, params?: any) {
    const completeApiUrl: string = this.getCompleteApiUrl(methodUrl);
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
      //'responseType'  : 'blob' as 'json'        //This also worked
    };
    if (params) {
      httpOptions['params'] = params;
    }
    return this.httpClient.get(completeApiUrl, httpOptions);

  }

  post(methodUrl, object) {
    const completeApiUrl: string = this.getCompleteApiUrl(methodUrl);
    return this.httpClient.post(completeApiUrl, object);
  }

  delete(methodUrl, object) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: object
    };

    const completeApiUrl: string = this.getCompleteApiUrl(methodUrl);
    return this.httpClient.delete(completeApiUrl, options);
  }

  private getCompleteApiUrl(methodUrl) {
    return appConstants.baseUrl + methodUrl;
  }
}
