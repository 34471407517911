import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "./authentication/authentication.service";
import {NavigationEnd, Router} from "@angular/router";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'blood-bank-management';
	isLogin = false;

	constructor(private authService: AuthenticationService, private router: Router) {
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) {
				this.isLogin = !(val.url === '/login' || val.url === '/forgot-password') && this.checkLogin();
			}
		});
	}

	ngOnInit(): void {
		this.checkLogin();
	}

	checkLogin(): boolean {
		if (this.authService.getKey() && this.authService.getUser()) {
			return true;
		}
		return false;
	}
}
