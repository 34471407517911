import {NgModule} from '@angular/core';
import {
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatFormFieldModule,
	MatGridListModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatNativeDateModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSidenavModule,
	MatSnackBarModule,
	MatTableModule,
	MatToolbarModule
} from "@angular/material";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LoaderComponent} from './component/loader/loader.component';
import {CommonModule} from "@angular/common";
import {NgxMaskModule} from "ngx-mask";
import {TokenInterceptor} from "./interceptors/token.interceptor";
import {LoaderInterceptor} from "./interceptors/loader.interceptor";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
	imports: [
		CommonModule,
		NgxMaskModule.forRoot(),
		HttpClientModule,
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatGridListModule,
		MatSnackBarModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatToolbarModule,
		MatSidenavModule,
		MatIconModule,
		MatListModule,
		MatCardModule,
		MatTableModule,
		MatExpansionModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatRadioModule,
		ReactiveFormsModule,
		FormsModule
	],
	declarations: [LoaderComponent],
	exports: [
		CommonModule,
		NgxMaskModule,
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatGridListModule,
		MatSnackBarModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		LoaderComponent,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatListModule,
		MatCardModule,
		MatTableModule,
		MatExpansionModule,
		MatSelectModule,
		MatDatepickerModule,
		MatRadioModule,
		ReactiveFormsModule,
		FormsModule
	],
	providers: [
		MatDatepickerModule,
		{provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
	],
})
export class SharedModule {
}
