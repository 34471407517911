import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AuthenticationModule} from "./authentication/authentication.module";
import {RouterModule} from "@angular/router";
import {AppRoutes} from "./app.routing";
import {SharedModule} from "./shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LoaderService} from "./shared/loader.service";
import {LoaderInterceptor} from "./shared/interceptors/loader.interceptor";
import {DashboardComponent} from './dashboard/dashboard.component';
import {TokenInterceptor} from "./shared/interceptors/token.interceptor";
import {CommonModule} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent
	],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(AppRoutes, {enableTracing: false}),
		HttpClientModule,
		AuthenticationModule,
		SharedModule,
		ReactiveFormsModule,
	],
	providers: [LoaderService,
		{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
