import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material";
import {AuthenticationService} from "../authentication.service";
import {appConstants} from "../../app.constant";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup;
	username: FormControl;
	password: FormControl;
	returnUrl: string;

	constructor(private _snackBar: MatSnackBar, private authService: AuthenticationService, private route: ActivatedRoute,
				private router: Router) {

	}

	ngOnInit() {
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
		this.createForm();
		if (this.authService.getKey() && this.authService.getUser()) {
			this.router.navigate([this.returnUrl]);
		}
	}

	createForm() {
		this.username = new FormControl(null, [Validators.required]);
		this.password = new FormControl(null, [Validators.required]);
		this.loginForm = new FormGroup({
			username: this.username,
			password: this.password
		});
	}

	onSubmit(event) {
		if (this.loginForm.valid) {
			const postObj = this.loginForm.getRawValue();
			this.authService.post(appConstants.api.login, postObj).subscribe((res: any) => {
				if (res.error) {
					this._snackBar.open('Invalid User!', '', {
						horizontalPosition: 'right',
						verticalPosition: 'top',
						duration: 2000
					});
				} else {
					this.authService.setUser(res.user);
					this.router.navigate([this.returnUrl]);
				}

			}, (err) => {
				console.error(err);
				this._snackBar.open('Something went wrong', '', {
					duration: 2000
				});
			});

		} else {
			this._snackBar.open('Required Parameter Missing', '', {
				duration: 2000
			});
		}
	}
}
