import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthenticationService} from "../../authentication/authentication.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(public authenticationService: AuthenticationService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.authenticationService.getKey();
		if (token) {
			let newParams = new HttpParams({fromString: req.params.toString()});
			newParams = newParams.append('auth_key', token);
			req = req.clone({
				params: newParams
			});
		}
		return next.handle(req);
	}
}
