/* Application routes configuration */
import {Routes} from '@angular/router';
import {LoginComponent} from './authentication/login/login.component';
import {ForgetPasswordComponent} from './authentication/forget-password/forget-password.component';
import {AuthGuard} from "./shared/auth.guard";
import {DashboardComponent} from "./dashboard/dashboard.component";

export const AppRoutes: Routes = [{
  path: 'login',
  component: LoginComponent
}, {
  path: 'forgot-password',
  component: ForgetPasswordComponent
}, {
  path: 'dashboard',
  component: DashboardComponent,
  canActivate: [AuthGuard]
}, {
  path: 'donor',
  canActivate: [AuthGuard],
  loadChildren: './donor/donor.module#DonorModule'
}, {
  path: 'blood-drive',
  canActivate: [AuthGuard],
  loadChildren: './blood-drive/blood-drive.module#BloodDriveModule'
}, {
  path: 'blood-registry',
  canActivate: [AuthGuard],
  loadChildren: './blood-registry/blood-registry.module#BloodRegistryModule'
}, {
  path: 'blood-center',
  canActivate: [AuthGuard],
  loadChildren: './blood-center/blood-center.module#BloodCenterModule'
}, {
  path: 'staff',
  canActivate: [AuthGuard],
  loadChildren: './staff/staff.module#StaffModule'
}, {
  path: 'reports',
  canActivate: [AuthGuard],
  loadChildren: './reports/reports.module#ReportsModule'
}, {
  path: 'sponsor',
  canActivate: [AuthGuard],
  loadChildren: './sponsor/sponsor.module#SponsorModule'
}, {
  path: '**',
  redirectTo: 'login'
}];

